import { useQuery } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';

import { type TrendingSearchesType, TrendingSearches } from '~/types/search';
import { validatorFactoryArray } from '@laam/lib/validator';
import creatApiInstance from '~/utils/create-api-instance';

const trendingSearchValidator =
	validatorFactoryArray<TrendingSearchesType>(TrendingSearches);

export const useTrendingSearches = (
	searchQuery: Ref<string>,
	isInputFocused: Ref<boolean>,
) => {
	const enabled = computed(() => isInputFocused.value && !!searchQuery.value);
	const { data } = useQuery({
		queryKey: ['search-query', searchQuery],
		retry: 2,
		enabled,
		queryFn: async () => {
			try {
				const api = creatApiInstance();
				const response = await api.get('/v1/trending-searches');

				if (response.status === 200) {
					try {
						return trendingSearchValidator.verify(response.data);
					} catch (e) {
						const err = new Error('Failed to verify trending searches data');
						Sentry.captureException(err, (scope) => {
							scope.setContext('errors', {
								error: e,
							});
							return scope;
						});
					}
				}
				const error = new Error('Failed to fetch search suggestions data');
				Sentry.captureException(error, (scope) => {
					scope.setContext('response', response.data);
					return scope;
				});
				throw error;
			} catch (error: any) {
				console.log('Failed to fetch trending searches data', error);
				Sentry.captureException(error);
				throw new Error(error.message);
			}
		},
	});

	return { data };
};
