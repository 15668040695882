<script setup lang="ts">
import type { TrendingSearchesType } from '~/types/search';
import { PhFire } from '@phosphor-icons/vue';

interface Props {
	trendingSearches: TrendingSearchesType;
}

defineProps<Props>();
const { $eventClient } = useNuxtApp();
const searchQuery: Ref<string> = inject('debouncedSearchQuery')!;
const handleFocusOut: () => void = inject('handleFocusOut')!;

const trendingSearchClick = (term: string, href?: string) => {
	$eventClient.sendEvent('trending-search-click', {
		searchQuery: searchQuery.value,
		term: term,
		href: href,
	});

	if (!href) {
		window.location.href = `https://laam.pk/search?search=${term}`;
	}
	handleFocusOut();
};
</script>

<template>
	<div class="flex flex-col lg:px-3xl">
		<div class="py-xl flex">
			<span class="text-sm font-semibold text-gray-500">Trending Searches</span>
		</div>
		<div class="flex w-full flex-wrap gap-md">
			<div
				v-for="(category, index) in trendingSearches"
				:key="index"
				class="border-gray-300 rounded-xs py-xs px-lg flex gap-xs border w-auto min-w-max"
			>
				<div v-if="category.href">
					<NuxtLink
						:to="`/${category.href}`"
						class="flex items-center gap-xs"
						@click="() => trendingSearchClick(category.term, category.href)"
					>
						<PhFire
							:size="12"
							:fill="'#F97066'"
							:color="'#F97066'"
							:weight="'fill'"
						/>
						<span class="text-sm font-medium text-gray-700 capitalize">
							{{ category.term }}
						</span>
					</NuxtLink>
				</div>
				<div v-else>
					<div
						class="flex items-center gap-xs cursor-pointer"
						@click="() => trendingSearchClick(category.term)"
					>
						<PhFire
							:size="12"
							:fill="'#F97066'"
							:color="'#F97066'"
							:weight="'fill'"
						/>
						<span class="text-sm font-medium text-gray-700 capitalize">
							{{ category.term }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
