<script setup lang="ts">
import TopSellingBrands from '~/components/search/TopSellingBrands.vue';
import TopSellingCategories from '~/components/search/TopSellingCategories.vue';
import SearchSuggestionsLoader from '~/components/search/SearchSuggestionsLoader.vue';
import type {
	SearchSuggestionsResponse,
	TrendingSearchesType,
} from '~/types/search.ts';
import TrendingSearches from './TrendingSearches.vue';

interface Props {
	suggestionsLoading: boolean;
	suggestions: SearchSuggestionsResponse;
	trendingSearches: TrendingSearchesType;
}

const props = defineProps<Props>();
const { deviceType } = useDeviceType();
const isMobile = deviceType.value === 'mobile';
</script>

<template>
	<div
		class="w-full bg-white lg:p-0 px-xl pb-xl overflow-hidden absolute z-[30]"
		:class="{
			'mt-md rounded-sm shadow-xl border border-gray-200': !isMobile,
		}"
	>
		<SearchSuggestionsLoader v-if="props.suggestionsLoading" />
		<div v-else class="py-md">
			<TopSellingBrands
				v-if="!!suggestions?.brands.length"
				:brands-data="
					suggestions.brands.length > 4
						? suggestions.brands.slice(0, 4)
						: suggestions.brands
				"
			/>
			<TopSellingCategories
				v-if="!!suggestions?.categories.length"
				:categories-data="
					suggestions.categories.length > 4
						? suggestions.categories.slice(0, 4)
						: suggestions.categories
				"
			/>
			<SearchOthers
				v-if="!!suggestions?.others.length"
				:searches-data="
					suggestions.others.length > 2
						? suggestions.others.slice(0, 2)
						: suggestions.others
				"
			/>
			<TrendingSearches
				v-if="!!trendingSearches?.length"
				:trending-searches="trendingSearches"
			/>
		</div>
	</div>
</template>
