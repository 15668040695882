<script setup lang="ts">
import { Button, Input } from '@laam/ui/base'; // Import TanStack components
import { PhMagnifyingGlass, PhX } from '@phosphor-icons/vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { FBConversion } from '~/utils/fbConversion.ts';
import { SEARCH_NODES } from '~/utils/enums.ts';
import { useSearchSuggestions } from '~/data/search-auto-suggestions.ts';
import { useTrendingSearches } from '~/data/trending-searches';
import { useDebounceFn } from '@vueuse/core';
import { type searchNode } from '~/types/search.ts';
import SearchNodes from '~/components/search/SearchNodes.vue';
import { getSearchQueryPath } from '~/utils/helpers.ts';
import type { BannerReasons } from '~/components/TopBanner.vue';

const route = useRoute();
const router = useRouter();

const { $eventClient } = useNuxtApp();
const topBannerReason = inject('topBannerReason') as Ref<BannerReasons>;
const { deviceType } = useDeviceType();
const isMobile = computed(() => deviceType.value === 'mobile');

const pathName = computed(() => route.path);

const filters = computed(() => {
	const filters = route.fullPath.split('?')[1] || '';
	if (!filters) return '';
	const params = new URLSearchParams(filters);
	params.delete('search');
	params.delete('node_id');

	return params.toString();
});

provide('filters', filters);

const nodeFromQuery = computed(() => {
	if (route.query?.node_id) {
		return (
			SEARCH_NODES.find(
				(node) => node.id === parseInt(route.query.node_id as string),
			) ?? SEARCH_NODES[0]
		);
	}

	return SEARCH_NODES[0];
});

provide('nodeFromQuery', nodeFromQuery);

const isMobileSearchOpen: Ref<boolean> = inject('isMobileSearchOpen')!;
const isSearchNodesOpen = ref(false);
provide('isSearchNodesOpen', isSearchNodesOpen);

const searchInputRef = ref<HTMLDivElement | null>(null);
const searchNodesRef = ref<HTMLDivElement | null>(null);
provide('searchNodesRef', searchNodesRef);

const isInputFocused = ref<boolean>(
	pathName.value !== '/search' ? true : false, //setting input focus to true so that on hydrating it is set to true
);

const filterValue = ref<string>((route?.query?.search as string) ?? ''); // Create a reactive data to store the search query
provide('filterValue', filterValue);

const debouncedSearchQuery = ref<string>(filterValue.value ?? '');
provide('debouncedSearchQuery', debouncedSearchQuery);

const selectedNode = ref<searchNode>(nodeFromQuery.value!);
provide('selectedNode', selectedNode);

const { data, isLoading: suggestionsLoading } = useSearchSuggestions(
	debouncedSearchQuery,
	selectedNode,
	isInputFocused,
);

const { data: TrendingData } = useTrendingSearches(
	debouncedSearchQuery,
	isInputFocused,
);

const searchQuery = async () => {
	if (filterValue.value === '') {
		if (pathName.value === '/search') {
			navigateTo('/'); // Navigate to homepage if current path is search otherwise stay on current route
		}
	} else {
		FBConversion.apiCall({
			event_name: 'search',
			custom_data: {
				search_string: filterValue.value,
			},
		});
		$eventClient.sendEvent('product-search', {
			query: filterValue.value,
			nodeId: nodeFromQuery.value!.id,
		});

		const searchPath = getSearchQueryPath(
			filters.value,
			nodeFromQuery.value!.id,
			selectedNode.value.id,
			filterValue.value,
			pathName.value,
		); //getting search query url path with applied filters, node id and search query

		await navigateTo(searchPath);
		handleFocusOut(); //always focus out on route change
	}
};

const searchSuggestionsDebounce = useDebounceFn(async () => {
	debouncedSearchQuery.value = filterValue.value!;
}, 500);

const handleInputChange = () => {
	searchSuggestionsDebounce();
};

const handleFocusIn = () => {
	isInputFocused.value = true;
};

const handleFocusOut = () => {
	isInputFocused.value = false;

	const inputField = document.getElementById('inputField');
	if (inputField) {
		inputField.blur();
	}
};
provide('handleFocusOut', handleFocusOut);

const handleSearchCancel = () => {
	if (pathName.value === '/search') {
		handleFocusOut();
		navigateTo('/');
	} else {
		isMobileSearchOpen.value = false;
	}
};

const clearSearchQuery = () => {
	filterValue.value = '';
	debouncedSearchQuery.value = '';
	isSearchNodesOpen.value = false;
	const inputField = document.getElementById('inputField');
	if (inputField) {
		inputField.focus();
	}
};

const handleClickOutside = (e: MouseEvent) => {
	if (
		searchInputRef.value &&
		!searchInputRef.value.contains(e.target as Node) &&
		searchNodesRef.value &&
		!searchNodesRef.value.contains(e.target as Node)
	) {
		if (isMobile.value && pathName.value !== '/search') {
			// when search overlay opens up, do not close on outside click
			return;
		} else isInputFocused.value = false;
	}
};

const handleEnterClick = () => {
	searchQuery();
};

const suggestedDataLength = computed(() => {
	if (suggestionsLoading.value) {
		return true;
	} else {
		return (
			!!data.value?.brands.length ||
			!!data.value?.categories.length ||
			!!data.value?.others.length ||
			!!TrendingData.value?.length
		);
	}
});

const showSearchSuggestions = computed(() => {
	if (debouncedSearchQuery.value) {
		//show suggestions when query length is greater or equal to 3 and if input field is focused and suggestions have at least data of one section.

		return isInputFocused.value;
	} else return false;
});

const showOverlay = computed(() => {
	return (
		pathName.value === '/search' &&
		showSearchSuggestions.value &&
		isMobile.value
	);
});

const overlayTop = computed(() => {
	if (topBannerReason.value) {
		return 'calc(var(--laam-mobile-search-input) + var(--laam-shipping-banner-height))';
	} else {
		return 'var(--laam-mobile-search-input)';
	}
});

watch(showOverlay, (newVal) => {
	if (newVal) {
		document.body.style.overflow = 'hidden';
	} else {
		document.body.style.overflow = 'auto';
	}
});

onMounted(() => {
	document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
	document.removeEventListener('click', handleClickOutside);
	document.body.style.overflow = 'auto';
});

router.beforeEach((to) => {
	if (to.name !== 'search') {
		filterValue.value = '';
		debouncedSearchQuery.value = '';
		selectedNode.value = SEARCH_NODES[0]!;
	}
});

// :class="{
// 					'lg:max-w-[45vw]': storeType !== 'OCTANE',
// 				}"

// :data-store-type="storeType"
// 		:data-header-visible="isHeaderVisible"
// 		:data-shipping-config="!!shippingConfig"

// FIXME: fix all the styles in dev server here
</script>
<template>
	<div class="w-full">
		<div
			v-if="showOverlay"
			class="fixed inset-0 bg-black bottom-0 opacity-30 z-[20]"
			:style="{ top: overlayTop }"
		></div>
		<div
			ref="searchInputRef"
			class="flex flex-col z-[30] bg-white w-full lg:border-b-0"
		>
			<div
				class="flex space-between lg:p-0 px-xl pt-xl"
				:class="{ 'pb-xl': !showOverlay }"
			>
				<div class="flex flex-row w-full">
					<SearchNodes />

					<div class="lg:gap-7xl flex flex-row items-center w-full">
						<Input
							id="inputField"
							v-model="filterValue"
							class="text-sm text-gray-500 z-[40] rounded-l-none flex items-center bg-gray-100 max-h-9xl rounded-medium gap-md border-gray-200"
							name="laam-search"
							placeholder="Search fashion"
							:auto-complete="'off'"
							@input="handleInputChange"
							@focus="handleFocusIn"
							@keydown.enter="handleEnterClick"
						>
							<template #leftIcon>
								<div>
									<PhMagnifyingGlass size="20" />
								</div>
							</template>

							<template #rightIcon>
								<Button
									v-if="isInputFocused && !!filterValue"
									class="laam-search-clear p-sm border-none bg-transparent hover:bg-transparent"
									:variant="isMobile ? 'secondary' : 'tertiary'"
									:size="'lg'"
									@click.stop="clearSearchQuery"
								>
									<span><PhX class="" /> </span>
								</Button>
							</template>
						</Input>
					</div>
				</div>
				<Button
					v-if="isMobile"
					class="px-md py-sm laam-search-cancel border-none bg-transparent hover:bg-transparent"
					:variant="isMobile ? 'secondary' : 'tertiary'"
					:size="isMobile ? 'xl' : 'lg'"
					s
					@click="handleSearchCancel"
				>
					<span>Cancel</span>
				</Button>
			</div>

			<div v-if="showSearchSuggestions && suggestedDataLength" class="relative">
				<SearchAutoSuggestions
					:suggestions-loading="suggestionsLoading"
					:suggestions="data!"
					:trending-searches="TrendingData!"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
/* TODO: add styles for marketplace */
.search-header[data-header-visible='true'][data-top-banner='false'] {
	z-index: 20;
	top: 0;
	@media (min-width: 768px) {
		top: var(--octane-topbar-height);
	}
}
.search-header[data-header-visible='true'][data-top-banner='true'] {
	z-index: 20;
	top: var(--laam-shipping-banner-height);
	@media (min-width: 768px) {
		top: var(--octane-topbar-height);
	}
}
.search-header[data-header-visible='false'][data-top-banner='false'] {
	z-index: 20;
	top: calc(0 - var(--octane-topbar-height-mobile));
	@media (min-width: 768px) {
		top: calc(0 - var(--octane-topbar-height));
	}
}
.search-header[data-header-visible='false'][data-top-banner='true'] {
	z-index: 20;
	top: calc(
		0 - var(--octane-topbar-height-mobile) - var(--laam-shipping-banner-height)
	);
	@media (min-width: 768px) {
		top: calc(0 - var(--octane-topbar-height));
	}
}
</style>
